<template>
  <v-container>
    {{ $config }}
  </v-container>
</template>
<script>
  export default {

    data () {
      return {

      }
    },

  }
</script>
